
      @use '_variables.scss' as *;
      @use '_mixins.scss' as *;
    
@use 'styles/_variables.scss' as *;
// no latest design given, just to keep the same display as prod
$light-yellow: #f2e9da;
$backdrop-black: rgba(26, 26, 26, 0.75);

.subheader {
  z-index: map-get($map: $zIndex, $key: header) - 2;
}

.headerWrapper,
.headerWrapperWhiteBg {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: map-get($map: $zIndex, $key: header);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 0.01%,
    rgba(0, 0, 0, 0) 100%
  );
}
.headerWrapper {
  @include dl-header-up {
    position: fixed;
  }
}

.headerPcWrapper {
  display: none;
  @include dl-header-up {
    display: block;
  }

  .backdrop {
    width: 100vw;
    height: 110vh;
    z-index: -1;
    top: 0;
    left: 0;
    position: fixed;
    backdrop-filter: blur(8px);
    background: $backdrop-black;
  }
}

.headerPc {
  display: flex;
  padding: 16px 24px 16px 68px;
  justify-content: space-between;

  &:hover {
    background: rgba(map-get($primary, 600), 0.5);
    transition:
      background-color 200ms ease-in-out,
      color 200ms ease-in-out;
  }

  @include dl-header-down {
    display: none;
  }

  .activatedTab,
  .tab :hover {
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: map-get($primary, 600);
      bottom: -4px;
      left: 0;
    }
  }

  .menuWrapper {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
  }
}
.headerPcWhiteBg {
  box-shadow: 0px 2px 8px 0px #00000014;
}

.accountMenu {
  border-radius: 8px;
  background: map-get($primary, 100);
  @include dl-header-down {
    display: none;
  }
  &:first-child {
    border: 8px 8px 0 0;
  }

  .items {
    padding: 12px 24px;
    cursor: pointer;
    &:hover {
      background: $light-yellow;
    }
    &:first-child {
      border-radius: 8px 8px 0 0;
    }
    &:last-child {
      background-color: white;
      border-radius: 0 0 8px 8px;
      text-align: center;
      &:hover {
        background: $light-yellow;
      }
    }
  }
}

.headerPcWhiteBg,
.headerPcMenuActivated {
  background: white;
  transition:
    background-color 300ms ease-in-out,
    color 300ms ease-in-out;

  &:hover {
    background: map-get($primary, 100);
  }
}

.headerMobileWrapper,
.panelWrapper {
  @include dl-header-up {
    display: none;
  }
}

.headerMobileWrapper {
  .headerMobile {
    background-color: map-get($white, warm);
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.panelWrapper {
  z-index: map-get($map: $zIndex, $key: header) - 1;
  position: fixed;
  width: 100%;

  .accountPanel {
    background-color: map-get($white, warm);

    & div:last-of-type {
      text-align: center;
      background-color: white;
    }
  }
}

.accordionWrapper {
  background-color: map-get($secondary, 100);
  border-radius: 12px;
  padding: 16px;

  .accordion {
    background-color: transparent;
    &:not(:last-child) {
      border-bottom: 1px solid map-get($grey, 400);
    }
    .accordionSummary {
      padding: 0 8px;
    }
    .accordionDetails {
      padding: 0 8px;
      & > div {
        margin-bottom: 8px;
      }
    }
  }
}

.menuList {
  background-color: map-get($white, cold);
  border-radius: 0 0 8px 8px;
  padding: 16px 24px;
}

.scheduleWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
