@use '_variables.scss' as *;

@mixin xs-down {
  @media screen and (max-width: #{$breakpoint-xs - 0.1px}) {
    @content;
  }
}

@mixin xs-up {
  @media screen and (min-width: #{$breakpoint-xs}) {
    @content;
  }
}

@mixin sm-down {
  @media screen and (max-width: #{$breakpoint-sm - 0.1px}) {
    @content;
  }
}

@mixin sm-up {
  @media screen and (min-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin md-down {
  @media screen and (max-width: #{$breakpoint-md - 0.1px}) {
    @content;
  }
}

@mixin md-up {
  @media screen and (min-width: #{$breakpoint-md}) {
    @content;
  }
}

@mixin dl-down {
  @media screen and (max-width: #{$breakpoint-dl - 0.1px}) {
    @content;
  }
}

@mixin dl-up {
  @media screen and (min-width: #{$breakpoint-dl}) {
    @content;
  }
}

// TODO: removed these two mixins after updating to the new header
@mixin dl-header-down {
  @media screen and (max-width: #{$breakpoint-dl-header - 0.1px}) {
    @content;
  }
}

@mixin dl-header-up {
  @media screen and (min-width: #{$breakpoint-dl-header}) {
    @content;
  }
}

@mixin lg-down {
  @media screen and (max-width: #{$breakpoint-lg - 0.1px}) {
    @content;
  }
}

@mixin lg-up {
  @media screen and (min-width: #{$breakpoint-lg}) {
    @content;
  }
}

@mixin xl-up {
  @media screen and (min-width: #{$breakpoint-xl}) {
    @content;
  }
}

@mixin xl-down {
  @media screen and (max-width: #{$breakpoint-xl - 0.1px}) {
    @content;
  }
}

@mixin standard-padding {
  padding: 0 max(108px, calc(100% - 1320px) / 2);
  @include md-down {
    padding: 0 80px;
  }
  @include sm-down {
    padding: 0 16px;
  }
}
